var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { height: _vm.vehicleUrl ? "auto" : "100%" } },
    [
      _c("cs-iframe", {
        staticClass: "mal-iframe",
        attrs: {
          width: "100%",
          height: "100%",
          src: `/certificate/${_vm.certificateId}/finance/appForm?notopbar=true`
        }
      }),
      _vm._v(" "),
      _vm.vehicleUrl
        ? _c(
            "b-button",
            {
              staticClass: "mx-auto",
              attrs: { variant: "orange" },
              on: { click: _vm.backToVehicle }
            },
            [_vm._v("\n        Back to vehicle\n    ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }