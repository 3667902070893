<template>
    <div class="d-flex justify-content-center">
        <loading-spinner :size="60" />
        <form ref="form" hidden :action="malUrl" method="POST">
            <input type="hidden" name="iframe-source" :value="hostname" />
            <input type="hidden" name="loanType" value="P" />
            <input type="hidden" name="appType" value="I" />
            <input :value="appRequest.channel" type="hidden" name="channel" />
            <input :value="appRequest.type" type="hidden" name="type" />
            <input :value="requestId" type="hidden" name="term" />
            <input
                :value="appRequest.firstName"
                type="hidden"
                name="firstName"
            />
            <input :value="appRequest.lastName" type="hidden" name="lastName" />
            <input :value="appRequest.address1" type="hidden" name="addr1" />
            <input :value="appRequest.city" type="hidden" name="city" />
            <input :value="appRequest.stateCode" type="hidden" name="state" />
            <input :value="appRequest.zip" type="hidden" name="zip" />
            <input :value="appRequest.email" type="hidden" name="email" />
            <input
                :value="appRequest.phoneAreaCode"
                type="hidden"
                name="home_phone_area_code"
            />
            <input
                :value="appRequest.phonePrefix"
                type="hidden"
                name="home_phone_prefix"
            />
            <input
                :value="appRequest.phoneSuffix"
                type="hidden"
                name="home_phone_suffix"
            />
            <input type="hidden" name="dob_month" value="" />
            <input type="hidden" name="dob_day" value="" />
            <input type="hidden" name="dob_year" value="" />
            <input :value="appRequest.vin" type="hidden" name="vin" />
            <input :value="appRequest.make" type="hidden" name="make" />
            <input :value="appRequest.model" type="hidden" name="model" />
            <input :value="appRequest.trim" type="hidden" name="trim" />
            <input :value="appRequest.year" type="hidden" name="year" />
            <input :value="appRequest.msrp" type="hidden" name="msrp" />
            <input :value="appRequest.invoice" type="hidden" name="invoice" />
            <input
                :value="appRequest.carsaverPrice"
                type="hidden"
                name="crsv_price"
            />
            <input
                v-if="appRequest.salesTax != null"
                type="hidden"
                name="fee_description"
                value="Total Sales Tax"
            />
            <input
                :value="appRequest.salesTax"
                type="hidden"
                name="sales_tax"
            />
            <input
                :value="appRequest.titleLicense"
                type="hidden"
                name="title_license"
            />
            <input
                type="hidden"
                name="fee_description_total"
                value="Total Title and registration fees"
            />
            <input
                type="hidden"
                name="dealer_fees_description"
                value="Processing and documentation fees"
            />
            <input
                :value="appRequest.dealerFees"
                type="hidden"
                name="dealer_fees"
            />
            <input type="hidden" name="is_traded_in" value="N" />
            <input
                :value="appRequest.requestedAmountFinanced"
                type="hidden"
                name="requested_amf"
            />
            <input
                :value="appRequest.totalCost"
                type="hidden"
                name="total_cost"
            />
            <input
                :value="appRequest.newOrUsed"
                type="hidden"
                name="new_used"
            />
            <input :value="appRequest.mileage" type="hidden" name="mileage" />
            <input :value="appRequest.source" type="hidden" name="source" />
            <input :value="appRequest.locale" type="hidden" name="language" />
            <input :value="appRequest.locale" type="hidden" name="locale" />
            <!-- this name differs on purpose from digital-retail due to the -->
            <input
                :value="appRequest.routeOneDealerId"
                type="hidden"
                name="route_one_dealer_id"
            />
            <input
                :value="appRequest.lenderIds"
                type="hidden"
                name="lender_ids"
            />
            <input
                :value="appRequest.lenderAppIds"
                type="hidden"
                name="lender_app_ids"
            />
            <input
                :value="appRequest.dealertrackPartnerId"
                type="hidden"
                name="dealertrack_partner_id"
            />
            <button type="submit" value="CONTINUE">
                Submit
            </button>
        </form>
    </div>
</template>

<script>
import api from "../../api";
import _ from "lodash";
import LoadingSpinner from "../../components/LoadingSpinner/index";

export default {
    name: "FinanceApp",
    components: { LoadingSpinner },
    props: {
        certificateId: {
            type: Number,
            required: true
        },
        requestId: {
            type: String,
            required: true
        },
        malUrl: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            appRequest: {
                channel: null,
                type: null,
                requestId: this.requestId,
                firstName: null,
                lastName: null,
                address1: null,
                city: null,
                stateCode: null,
                zip: null,
                email: null,
                phoneAreaCode: null,
                phonePrefix: null,
                phoneSuffix: null,
                vin: null,
                make: null,
                model: null,
                trim: null,
                year: null,
                msrp: null,
                invoice: null,
                carsaverPrice: null,
                salesTax: null,
                titleLicense: null,
                dealerFees: null,
                requestedAmountFinanced: null,
                totalCost: null,
                newOrUsed: null,
                mileage: null,
                source: null,
                locale: null,
                routeOneDealerId: null,
                lenderIds: null,
                lenderAppIds: null,
                dealertrackPartnerId: null
            }
        };
    },

    computed: {
        hostname() {
            return window.location.hostname;
        }
    },

    mounted() {
        const component = this;
        api.get(`/certificates/${this.certificateId}/finance/app`, {
            loanRequestId: this.requestId
        })
            .then(response => {
                component.setAppRequest(response.data);
                localStorage.setItem(
                    "carsaver_malform_request_id",
                    this.requestId
                );
                setTimeout(component.submitForm, 500);
            })
            .catch(error => {
                console.error(error);
            });
    },

    methods: {
        setAppRequest(data) {
            const phoneNumber = data.phone
                .toString()
                .replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "$1-$2-$3")
                .split("-");

            this.appRequest = {
                ..._.omit(data, ["phone"]),
                phoneAreaCode: phoneNumber[0],
                phonePrefix: phoneNumber[1],
                phoneSuffix: phoneNumber[2]
            };
        },

        submitForm() {
            this.$refs.form.submit();
        }
    }
};
</script>
