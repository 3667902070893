<template>
    <div :style="{ height: vehicleUrl ? 'auto' : '100%' }">
        <cs-iframe
            width="100%"
            height="100%"
            :src="`/certificate/${certificateId}/finance/appForm?notopbar=true`"
            class="mal-iframe"
        />
        <b-button
            v-if="vehicleUrl"
            variant="orange"
            class="mx-auto"
            @click="backToVehicle"
        >
            Back to vehicle
        </b-button>
    </div>
</template>

<script>
import Api from "@/api";
import CsIframe from "Components/CsIframe";

export default {
    components: { CsIframe },

    props: {
        certificateId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            intervalId: null,
            vehicleUrl: null
        };
    },

    created() {
        const checkMAL = () => {
            const formWasSubmitted =
                localStorage.getItem("carsaver_malform") === "thankyou";
            const requestId = localStorage.getItem(
                "carsaver_malform_request_id"
            );

            if (formWasSubmitted) {
                clearInterval(this.intervalId);

                localStorage.removeItem("carsaver_malform");
                localStorage.removeItem("carsaver_malform_request_id");

                Api.get(`/finance/requests/${requestId}/vehicleUrl`)
                    .then(response => {
                        this.vehicleUrl = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        };

        this.intervalId = setInterval(checkMAL, 200);
    },

    methods: {
        backToVehicle() {
            document.location = this.vehicleUrl;
        }
    }
};
</script>
