var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("TEST APP FORM")]),
      _vm._v(" "),
      _c("b-button", { on: { click: _vm.submit } }, [
        _vm._v("\n        SUBMIT\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }