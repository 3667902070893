<template>
    <div class="text-center">
        <h1>Thank You</h1>
        <p>Your finance application was submitted successfully.</p>
    </div>
</template>

<script>
export default {
    name: "MalRedirect",
    created() {
        localStorage.setItem("carsaver_malform", "thankyou");
        console.log("MAL redirect");
    }
};
</script>
