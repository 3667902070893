<template>
    <div>
        <h1>TEST APP FORM</h1>

        <b-button @click="submit">
            SUBMIT
        </b-button>
    </div>
</template>

<script>
export default {
    methods: {
        submit() {
            console.log("Submit");
            document.location = "/malredirect";
        }
    }
};
</script>
